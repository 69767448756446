import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "C:/Users/pmaxh/Documents/node-projects/website/src/components/page-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Npm2neo4j is a program that downloads every NPM module and saves it to a `}<a parentName="p" {...{
        "href": "https://neo4j.com/"
      }}>{`Neo4j`}</a>{` database.`}</p>
    <p><a parentName="p" {...{
        "href": "/images/npmgraph.png"
      }}><img parentName="a" {...{
          "src": "/images/npmgraph-small.png",
          "alt": "Picture of database"
        }}></img></a></p>
    <p>{`The source for the app is hosted `}<a parentName="p" {...{
        "href": "https://github.com/pmaxhogan/npm2neo4j/"
      }}>{`on Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      